.card {
    align-items: flex-start;
    width: 66%;
}

.evaluationQuestionWrapperCard {
    overflow-x: auto;
}

.TableOfContaintHeadingContainer {
    display: flex;
    margin-bottom: 10px;
    justify-content: space-around;
}

.TableOfContaintHeadingAttempted {
    border-radius: 2px;
    margin-top: 10px;
    text-align: center;
    /* border: 0.5px solid #ffa726; */
    /* background:#EFD7AF80; */
    background: #53c053;
    color: aliceblue;
    width: 70px;
    margin-inline: 5px;
    font-family: Poppins;
    font-size: 16px;
}

.TableOfContaintHeadingRemaining {
    border-radius: 2px;
    margin-top: 10px;
    text-align: center;
    border: solid 1px #dadada;
    /* background:#EFD7AF80; */
    background: #ffff;
    color: #443a3a;
    width: 70px;
    margin-inline: 5px;
    font-family: Poppins;
    font-size: 16px;
}

.TableOfContaintHeading {
    /* padding: 4px; */
    border-radius: 2px;
    margin-top: 10px;
    text-align: center;
    /* border: 0.5px solid #ffa726; */
    /* background:#EFD7AF80; */
    background: #f69f23;
    /* //linear-gradient(60deg, #ffa726, #fb8c00); */
    color: aliceblue;
    width: 71px;
    margin-inline: 5px;
    font-family: Poppins;
    font-size: 16px;
}

.TableOfContaintHeadingTotal {
    /* padding: 4px; */
    border-radius: 2px;
    margin-top: 10px;
    text-align: center;
    /* border: 0.5px solid #ffa726; */
    /* background:#EFD7AF80; */
    background: #1dc8c9;
    color: aliceblue;
    width: 71px;
    margin-inline: 5px;
    font-family: Poppins;
    font-size: 16px;
}

.TableOfContaintHeading h6 {
    font-size: 12px;
    color: white;
}

/*  */
/* Add these styles to your QuestionWrapper.module.css or wherever appropriate */

.grayCard {
    background-color: gray;
}

.resultHalfCircle {
    font-size: 30px;
    font-family: Poppins;
    font-weight: bold;
    display: inline-block;
    background-color: #f8cb1e;
    color: #001124;
    padding: 20px 30px;
    width: 200px;
    text-align: center;
    margin-top: 0px;
    border-top-left-radius: 100px;
    /* Make the top half circular */
    border-top-right-radius: 100px;
    /* Make the top half circular */
    position: relative;
    height: 100px;
    /* Adjust height to control the curve size */
    line-height: 1;
}

.resultHalfCircle span {
    font-family: Poppins;
    color: #001124;
    font-size: 18px;
    font-weight: normal;
}

.resultInCircle {
    text-wrap-mode: nowrap !important;
}

.evaluationQuestionWrapperCard {
    padding: 20px;
    background-color: #f5f6fa;
}

.cardbody {
    padding: 20px;
    background-color: white;
}

/*  */
@media only screen and (max-width: 769px) {

    .gridOnMobile {
        padding: 0px 11px 0px 15px !important;
    }

    .cardbody {
        padding: 0% 0% 0% 1% !important
    }
}

@media only screen and (min-width:769px) {
    .tableHeadings {
        font-size: 16px;
    }
}