.Header {
    background-color: #ffffff;
    height: 56px;
}

.links {
    padding: 1%;
    justify-content: end;
    text-align: right;

}

.navlink {
    font-weight: 800;
    top: 17px;
    color: #8C8C8C;
}

.Mobilenavlink {
    font-weight: 800;
    top: 17px;
    color: #ffffff;
}