.floatingHead {
  padding: 8px;
  text-align: center;
  float: left;
  border-radius: 8px;
  height: 60px;
  width: 50px;
  margin: 5px;
  background: #fff1e8;
  color: #ff802b;
}

.head {
  font-family: Roboto, Helvetica, Arial, sans-serif;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  /* border: 0.5px solid #8A8A8A; */
  box-shadow: none;
  margin: 10px 0px 10px 0px;
}

.headtitle {
  padding-top: 1px;
}

.body {
  text-align: start;
  /* margin-inline-start: 15px; */
  color: #8a8a8a;
  line-height: 24px;
  font-family: "Poppins";
}

.previewButton {
  background: none;
  border: none;
  color: rgb(58, 58, 162);
}

.messege {
  /* border: 0.5px solid #8A8A8A; */
  /* border-radius: 5px; */
  /* padding: 5px; */
  margin: 11px 0px 1px 0px;
}

.notificationcontainer {
  margin: 5px 15px;
}

.holder {
  cursor: pointer;
}

.holder img {
  max-width: 50%;
  height: auto;
}

.messegeDesc {
  margin: 3px 0px 0px 8px;
}
