.footer4Actions {

    /* background-color: #F7EBD7; */
    bottom: 0;
    width: 100%;
    text-align: center;
    /* border-top: 0.5px solid #ec9706; */
    box-shadow: 0 -4px 4px 0 rgba(0, 0, 0, 0.05);
    background-color: #fff;
}

/* web screen  */
@media only screen and (min-width: 769px) {
    .footer4Actions {
        position: absolute;
        height: 8%;

    }

    .HeaderIcon {
        float: left;
    }

    .showOnMobile {
        display: none;
    }

    .showOnWeb {
        box-shadow: -1px -9px 15px #aaaaaa;
    }

    .questionBody {
        /* commented bcz of player set to fullscreen */
        max-height: 83vh;
        /* 499px; */
        overflow-y: auto;
        overflow-x: auto;
    }
}

/* mobile screen */
@media only screen and (max-width: 769px) {
    .footer4Actions {
        position: fixed;
        height: 9%;

    }

    .HeaderIcon {
        display: none;
        float: left;
    }

    .showOnWeb {
        display: none;

    }

    .TOCHover {
        width: 90%;
        margin-left: 10%;
        background: #F9F4F9;
        position: fixed;
        z-index: 9;
        box-shadow: -25px 9px 6px #aaaaaa;
    }

    .questionBody {
        overflow-y: auto;
        overflow-x: auto;
    }
}