.gridContainer {
    overflow: hidden;
}

.flexContainer {
    display: flex;
    flex-direction: row;
}
.tablefont{
  font-family: Roboto, Helvetica, Arial, sans-serif;  ;
  font-size: 14px !important ;
  font-weight: 500;
  line-height: 15px;
}
@media (max-width: 800px) {
    .flexContainer {
      flex-direction: column;
    }
  }
