.tablefont {
  font-family: Poppins;
  /* Roboto, Helvetica, Arial, sans-serif; */
  font-size: 14px !important;
  font-weight: 500;
  color: #594e4e !important;
  /* line-height: 15px; */
}

.treeContainer {
  padding: 5% 5% 3% 4%;
  border-radius: 5px;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.05);
  border: solid 1px #e4e4e4;
  background-color: #fff;
}

.subName {
  font-family: Poppins;
  /* Roboto, Helvetica, Arial, sans-serif; */
  font-size: 14px !important;
  font-weight: 500;
  line-height: 15px;
}

.tableRow button {
  height: 44px;
  width: 47px;
  padding: 5px;
  border-radius: 50%;
  /* color: white; */
  border: none;
  align-items: right;
}

.yourScore {
  background-color: #4c8055;
  color: #ffff;
}

.avgScore {
  background-color: #9da39d;
  color: #ffff;
}

.topScore {
  background-color: #34c0d3;
  color: #ffff;
}

.tableHead {
  /* background: lavender; */
}

.tablePieHeirarchyHeading {
  font-family: Poppins;
  color: #012248;
  font-weight: 600;
  font-size: 24px;
}

/* .tableHeadingPiHeirarchy {
  color: rgb(251, 140, 0);
  font-family: Roboto, Helvetica, Arial, sans-serif;
  font-size: 12px !important;
  font-weight: 500;
  line-height: 15px;
  float: left;
  width: 80px;
  font-weight: 700;
  text-align: center;
} */

.tableHeading {
  color: rgb(251, 140, 0);
  font-family: Poppins;
  /* Roboto, Helvetica, Arial, sans-serif; */
  /* font-size: 9px !important; */
  font-weight: 500;
  line-height: 15px;
  float: left;
  width: 80px;
  font-weight: 700;
  text-align: center;
}

.Rectangle72 {
  /* width: 315px; */
  height: 130px;
  /* margin: 24px 12px 0 42.5px; */
  /* padding: 43px 103px; */
  border-radius: 5px;
  border: solid 1px #e4e4e4;
  background-color: #fff;
  align-content: center;
  text-align: center;
}

.resultHalfCircle {
  font-size: 30px;
  font-family: Poppins;
  font-weight: bold;
  display: inline-block;
  background-color: #f8cb1e;
  color: #001124;
  padding: 47px 30px;
  width: 200px;
  text-align: center;
  margin-top: 0px;
  border-top-left-radius: 100px;
  /* Make the top half circular */
  border-top-right-radius: 100px;
  /* Make the top half circular */
  position: relative;
  height: 130px;
  /* Adjust height to control the curve size */
  line-height: 1;
}

.resultHalfCircle span {
  font-family: Poppins;
  color: #001124;
  font-size: 18px;
  font-weight: normal;
}

.resultInCircle {
  text-wrap-mode: nowrap !important;
}

.resultItem {
  border-radius: 5px;
  border: solid 1px #e4e4e4;
  background-color: #fff;
  justify-content: space-between;
  display: flex;
}

.rectangle73 {
  /* width: 315px; */
  height: 130px;
  /* margin: 24px 12px 0 42.5px; */
  /* padding: 43px 103px; */
  border-radius: 5px;
  /* border: solid 1px #e4e4e4; */
  /* background-color: #fff; */
  align-content: center;
  text-align: center;
}

.container {
  justify-content: space-between;
  background-color: #f5f6fa;
}

/* Web Screen */


@media only screen and (min-width: 769px) {
  .tableHeading {
    font-size: 15px !important;
  }

  .container {
    padding: 10px 50px;
    width: auto !important;
    margin: 0 !important;
    background-color: #f5f6fa;

  }

  .tableHeadingPiHeirarchy {
    color: rgb(251, 140, 0);
    font-family: Poppins;
    /* Roboto, Helvetica, Arial, sans-serif; */
    font-size: 12px !important;
    font-weight: 600;
    line-height: 15px;
    float: left;
    width: 80px;
    text-align: center;
  }
}

/* Mobile screen */
@media only screen and (max-width: 769px) {
  .container {
    padding-inline: 5px;
  }

  .tableHeading {
    font-size: 9px !important;
  }

  .tableHeadingPiHeirarchy {
    color: rgb(251, 140, 0);
    font-family: Poppins;
    /* Roboto, Helvetica, Arial, sans-serif; */
    font-size: 12px !important;
    font-weight: 500;
    line-height: 15px;
    float: left;
    width: 71px;
    font-weight: 700;
    text-align: end;
  }
}