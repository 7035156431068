.cardHeader {
    font-family: Poppins !important;
    font-size: 16px !important;
    font-weight: 600 !important;
    text-align: initial;
    color: #000;
}

.actionContainer {
    padding: 5px 0px 21px 0px !important;
}

.cardActionsButtons {
    /* font-size: 10px !important; */
    transform: scale(1.0, 1.5);
    font-weight: 700 !important;
    /* margin-left: 18px !important; */
}

.cardDescription {
    font-size: 10px !important;
    text-align: left;
    font-family: Poppins !important;

    margin: 4px 0 21px 0;
    padding: 2px 14px 0 12px;
    border-radius: 21px;
    border: 1px solid #f2f1f1;
    float: left;
    background-color: #f2f1f1;
}

.cardActionButtons {
    border-radius: 0 !important;
    width: 100% !important;
    padding: 16px 19px 16px 29px !important;
    /* background: rgba(255, 166, 36, 0.1); */
    color: #594e4e !important;
    font-family: Poppins !important;
    font-size: 12px !important;
    text-transform: capitalize;
}

.Assessments {
    background-color: rgba(255, 166, 36, 0.1) !important;
}

.inprogress {
    background-color: rgba(230, 60, 56, 0.1) !important;
}

.pending {
    background-color: rgba(35, 196, 216, 0.1) !important;
}

.completed {
    background-color: rgba(98, 184, 102, 0.1) !important;
}