.Rectangle5 {
  /* width: 1440px; */
  /* height: 493px; */
  /* margin: 139px 0 1px; */
  padding: 130px 1px 54px 0px;
  background-color: #002147;
}

.testSeriesHeader {
  /* width: 355px; */
  /* height: 48px; */
  margin: 0 10px 0 10px;
  font-family: Poppins !important;
  /* font-size: 32px !important; */
  font-weight: bold !important;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #fff !important;
}

.TestSeriesDescription {
  /* width: 766px; */
  min-height: 140px;
  margin: 13px 13px 13px 13px;
  font-family: Poppins !important;
  font-size: 18px !important;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #fff !important;
}

.Rectangle27 {
  /* width: 293px; */
  height: 60px;
  /* margin: 139px 12px 12px 2px; */
  /* margin: 12px; */
  padding: 15px 2%;
  /* 26px 5px 26px 15px; */
  border-radius: 5px;
  border: solid 1px #e2bef1;
}

.NumberofTests {
  /* width: 150px; */
  height: 18px;
  margin: 0 0 0 8.4px;
  font-family: Poppins;
  /* font-size: 18px; */
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #fff;
}

.Vector {
  /* width: 29.6px; */
  height: 24px;
  flex-grow: 0;
  /* margin: 0 8.4px 0 0; */
  /* background-color: #fff; */
}

.Rectangle28 {
  /* width: 456px; */
  height: 60px;
  /* margin: 12px; */
  /* margin: 139px 81px 12px 12px; */
  /* padding: 26px 5px 26px 15px; */
  padding: 15px 2%;
  border-radius: 5px;
  border: solid 1px #f5cdde;
}

@media (max-width: 600px) {

  .NumberofTests,
  .Duration,
  .price,
  .ValidUntil,
  .NumberofStudentsEnrolled {
    font-size: 14px;
    /* Reduce font size for smaller screens */
  }
}

@media (max-width: 400px) {

  .NumberofTests,
  .Duration,
  .price,
  .ValidUntil,
  .NumberofStudentsEnrolled {
    font-size: 16px;
    /* Reduce font size for smaller screens */
  }
}

.NumberofStudentsEnrolled {
  /* width: 258px; */
  height: 18px;
  margin: 0 0 0 8.4px;
  font-family: Poppins;
  /* font-size: 18px; */
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #fff;
}

.Rectangle29 {
  /* width: 154px; */
  height: 60px;
  /* margin: 12px; */
  /* padding: 24px 5px 11px 15px; */
  padding: 15px 4%;
  border-radius: 5px;
  border: solid 1px #f9ded7;
}

.price {
  /* width: 61px; */
  height: 18px;
  margin: 0 0 0 8.4px;
  font-family: Poppins;
  /* font-size: 18px; */
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #fff;
}

.discountPrice {
  width: 43px;
  height: 18px;
  margin: 0 2px 22px 34px;
  font-family: Poppins;
  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #9e9e9e;
}

.Duration {
  /* width: 80px; */
  height: 18px;
  margin: 0 0 0 8.4px;
  font-family: Poppins;
  /* font-size: 18px; */
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #fff;
}

.Rectangle30 {
  /* width: 293px; */
  height: 60px;
  /* margin: 12px; */
  /* padding: 21px 5px 21px 11px; */
  padding: 15px 3%;
  border-radius: 5px;
  border: solid 1px #c6f8e5;
}

.Rectangle31 {
  /* width: 293px; */
  height: 60px;
  /* margin: 12px; */
  /* padding: 22px 5px 22.5px 15px; */
  padding: 15px 2%;
  border-radius: 5px;
  border: solid 1px #cce1f2;
}

.ValidUntil {
  /* width: 190px; */
  height: 18px;
  margin: 0 0 0 8.4px;
  font-family: Poppins;
  /* font-size: 18px; */
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #fff;
}

.Rectangle25 {
  /* width: 538px; */
  height: 56px;
  margin: 9px 0 0 0 !important;
  /* padding: 14px 206.9px 18.3px 227px; */
  border-radius: 10px;
  background-color: #f8cd28 !important;
  color: #002147 !important;
  font-family: Poppins !important;
  font-size: 18px !important;
  text-transform: none !important;
}

.VectorArrow {
  width: 20.1px;
  height: 19.7px;
  margin: 4px 0 0;
  transform: rotate(-41.76deg);
  /* background-color: #002147; */
}

.Schedule {
  /* width: 368px;
    height: 18px;
    margin: 0 1010px 48px 0;
    padding: 4px 184px 0 164px; */
  margin-top: 5rem;
  font-family: Poppins !important;
  /* font-size: 32px !important; */
  font-weight: 600 !important;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left !important;
  color: #002147 !important;
}

.ScheduleText {
  /* width: 1372px;
    height: 44px; */
  margin: 30px 6px 16px 0;
  font-family: Poppins;
  font-size: 18px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left !important;
  color: #2d2929;
}

.AccordianArrow {
  transform: rotate(270deg);
  font-size: x-large !important;
}

.PassedSchedule {
  /* width: 194px; */
  /* height: 18px; */
  /* margin: 0 1080px 0 0; */
  margin-left: 17px !important;
  font-family: Poppins !important;
  font-size: 18px !important;
  font-weight: 600 !important;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #2d2929;
}

.Rectangle38 {
  /* width: 1378px; */
  /* height: 66px; */
  /* margin: 16px 0 24px;
    */
  padding: 24px 40px 24px 48px;
  box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.05);
  background-color: #fff;
}

.AccordianSummary {
  height: 66px !important;
  padding: 20px 10px 20px 0 !important;
}

.Rectangle39 {
  /* width: 1378px; */
  /* height: 508px; */
  margin: 24px 0 0 !important;
  /* padding: 24px 0 69px 10px; */
  box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.05) !important;
  background-color: #fff !important;
  color: #686262 !important;
  border: #686262 solid 1px !important;
  font-family: Poppins !important;
  font-size: 18px !important;
  text-transform: none !important;
}

.Rectangle26 {
  width: 158px !important;
  height: 38px !important;
  /* flex-grow: 0; */
  padding: 10px 11px 10px 15px !important;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1) !important;
  background-color: #fff !important;
  /* width: 111px; */
  /* height: 30px; */
  /* background: blueviolet; */
  position: absolute;
  z-index: 1;
  /* color: white; */
  /* top: 165px; */
  /* right: 34%; */
  margin: -55px 1px 1px -253px !important;
  color: #47515d !important;
  text-align: left !important;
  font-family: Poppins !important;
  font-size: 24px !important;
  font-weight: bold;
}

.defaultBackground {
  background-color: #f2f2f2;
}

.priceBadge {
  position: absolute;
  top: 10px;
  /* Adjust for consistent placement */
  left: 10px;
  background-color: #dd2466;
  color: #ffffff;
  padding: 5px 10px;
  border-radius: 12px;
  font-weight: bold;
  font-size: 14px;
  z-index: 1;
  /* Ensure it appears above the image */
}

.priceBadge1 {
  position: absolute;
  bottom: 105px;
  left: 0px;
  background-color: white;
  color: gray;
  padding: 5px 10px;
  /* border-radius: 12px; */
  font-size: 16px;
  width: 25%;
  color: #47515d !important;
  text-align: left !important;
  font-family: Poppins !important;
  font-size: 24px !important;
  font-weight: bold;
}

/* for large screens */
@media (min-width: 1440px) {
  .testSeriesHeader {
    font-size: 32px !important;
  }
}

/* for web */
@media screen and (min-width: 768px) and (min-height: 538px) {

  .Schedule,
  .testSeriesHeader {
    font-size: 32px !important;
  }
}

/* for tablets */
@media only screen and (min-width: 768px) and (max-width: 1023px) {

  .Schedule,
  .testSeriesHeader {
    font-size: 25px !important;
  }
}

/* for mobile */
@media only screen and (max-width: 767px) {

  .Schedule,
  .testSeriesHeader {
    font-size: 25px !important;
  }
}