.container {
  /* border: 2px solid #ccc; */
  border-radius: 20px;
  /* padding: 16px;
    margin: 16px 0 */
}

.container::after {
  content: "";
  clear: both;
  display: table;
}

.container img {
  float: left;
  margin-right: 20px;
  border-radius: 50%;
}

.container span {
  font-size: 20px;
  margin-right: 15px;
}

.filterButton {
  background: transparent !important;
  border: solid 1px !important;
  width: 44px !important;
  height: 44px !important;
  margin-right: 10px !important;

}

.filterIcon {
  font-size: x-large !important;
  color: #192147 !important;
}

.filterLabel {
  /* width: 52px;
      height: 23px; */
  /* flex-grow: 0; */
  /* margin: 11px 15px 11px 10px; */
  padding: 7px 10px;
  font-family: Poppins !important;
  font-size: 16px !important;
  font-weight: normal !important;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #fff;
}

.dividerX {
  margin: 1vh 23px 2vh 15px !important;
  background-color: #192147 !important;
}

@media (max-width: 500px) {
  .container {
    text-align: center;
  }

  .container img {
    margin: auto;
    float: none;
    display: block;
  }
}

@media only screen and (max-width: 600px) {
  .hide {
    display: none;
  }
}

@media only screen and (min-width: 600px) {
  .showonMobile {
    display: none;
    border-bottom: none;
  }
}

@media (min-width: 460px) {
  .container {
    background-color: #ffff;
  }
}

#table {
  border-radius: 5px;
}

.testName {
  font-weight: 'bold';
  text-align: left !important;
}

.tableHead {
  background-color: #EC9706;
  color: #ffff;
  font-weight: bold !important;

}

.tableHead th h5 {
  text-align: center;
}

.view {
  background-color: #EC9706;
  border-radius: 10px;
  color: #ffff;
  border: none;
  white-space: nowrap;
  padding: 3px;
}

.analytics {
  background-color: #198754;
  border-radius: 10px;
  color: #ffff;
  border: none;
  white-space: nowrap;
  padding: 3px;
  margin: 10px;
}

/* new Test view styles copied from notification */
.notificationText {
  /* text-align: start;
  margin-inline-start: 15px; */
  /* color: #8A8A8A; */

  text-align: start;
  margin-inline-start: 15px;
  margin-top: 0;
  margin-bottom: 0;
  font-size: 12px;
}

.notificationText small {
  color: #594e4e;
  font-family: Poppins;
  font-size: 10px;
  font-weight: 600;
  /* #ec9706; */
}

/* .notificationText span {
  margin-inline: 5px;
} */

.notificationTextTitle {
  text-align: start;
  margin-inline-start: 15px;
  font-family: Poppins;
  font-weight: 600;
  font-size: 12px;
  line-height: 24px;
  margin-bottom: 0;
  /* Remove bottom margin */
}

.Loader {
  color: #012248 !important;
  font-family: Poppins !important;
}

.notificationHead {
  /* color: rgb(255, 128, 43); */
  color: #012248;
  font-family: Poppins;
  font-size: 24px;
  font-weight: 600;
  text-align: left;
  margin: 12px 12px 12px 12px;
}

.card {
  /* background-color: #ff9800 !important; */
  /* color: #ffff !important; */
  font-family: Poppins !important;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  /* border: 0.5px solid #8A8A8A; */
  border-radius: 2px !important;
  box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.05) !important;
  margin: 12px 12px 12px 12px;

}

.floatingHeadWait {
  float: left;
  border-radius: 2px;
  padding: 6px;
  height: 60px;
  width: 61px;
  margin: 5px;
  background: #d3f3f7;
  /* #e8e8e8; 
  #403d3d;
  */
  color: #594e4e;
  font-family: Poppins !important;
  font-size: 12px;
  display: grid;
  line-height: normal;
  align-items: center !important;
}

.floatingHeadPass {
  float: left;
  border-radius: 2px;
  height: 60px;
  padding: 6px;
  width: 61px;
  margin: 5px;
  background: #e4f5e5;
  /* color: #07860d; */
  color: #594e4e;
  font-family: Poppins !important;
  font-size: 12px;
  display: grid;
  line-height: normal;
  align-items: center !important;
}

.floatingHeadFail {
  float: left;
  border-radius: 2px;
  padding: 6px;
  height: 60px;
  width: 61px;
  margin: 5px;
  background: #ffe5e5;
  /* color: #e80404; */
  color: #594e4e;
  font-family: Poppins !important;
  font-size: 12px;
  display: grid;
  line-height: normal;
  align-items: center !important;
}

.floatingHeadNA {
  float: left;
  border-radius: 2px;
  padding: 6px;
  height: 60px;
  width: 61px;
  margin: 5px;
  color: #594e4e !important;
  font-family: Poppins !important;
  font-size: 12px;
  display: grid;
  line-height: normal;
  align-items: center !important;
  /* background: #ffeacd; */
  /* color: #f79205; */
}

.Line34 {
  width: 1px;
  height: 25px;
  flex-grow: 0;
  margin: 18px 19.5px 17px 15.5px;
  transform: rotate(-270deg);
  border: solid 1px #e0e0e0;
}

.filterButtonFonts {
  /* width: 79px;
    flex-grow: 0; */
  margin: 0 12px 10px 0 !important;
  /* padding: 13px 28px 12px !important; */
  border-radius: 78px !important;
  height: 40px;
  font-family: Poppins !important;
  font-size: 13px !important;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #192147 !important;
  text-transform: none !important;
}

.Rectangle22 {
  /* width: 1440px; */
  /* height: 64px; */
  text-align: start;
  flex-grow: 0;
  margin: 66px 0 64px;
  padding: 6px 10px 0 0px;
  /* background-color: #1d2b69 !important; */
}

.Rectangle23 {
  /* width: 1440px; */
  /* height: 64px; */
  text-align: start;
  flex-grow: 0;
  margin: 66px 0 64px;
  padding: 6px 10px 0 21px;
  /* background-color: #1d2b69 !important; */
}

/* Show mobile filter only on small screens */
.mobileFilter {
  display: none;
  float: right;
}

.desktopFilter {
  display: block;
}

/* Media Query to show mobile filters on small screens */
@media (max-width: 768px) {
  .Rectangle22 {
    display: none;
  }

  .mobileFilter {
    display: block;
    float: right;
  }
}

.mobileFilterDrawer {
  padding: 16px 30px;
  background-color: #fff;
}

.filterButtonFonts {
  width: 100%;
  margin-bottom: 10px;
  text-align: left;
  padding: 8px 16px;
}

.filterLabel {
  font-weight: bold;
}

.filterLabelMobile {
  font-weight: bold;
  color: #1d2b69;
}

.filterIcon {
  color: #1d2b69;
  font-size: 24px;
}

.searchBar {
  box-shadow: none !important;
  border-radius: 5px;
  border: 0.30000001192092896px solid #707070;
  margin-inline-end: 111px;
}

.searchIcon {
  height: 20px;
  width: 20px;
}