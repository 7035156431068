table {
  border-collapse: collapse;
  width: 100%;
  line-height: 3.1;

}

td,
th {
  /* text-align: left; */
  padding: 8px;
  border-bottom: 0.5px solid #dddddd;
  padding-left: 2% !important;
}

.spanrow td {
  border-style: none;
  color: #aaaaaa;
  /* font-style: italic; */
}

.Head {
  text-align: center;
  color: #ffa726;
}

.HeadLeft {
  color: #ffa726;
}

@media screen and (max-width:425px) {
  table {
    font-size: 3vw !important;
  }
}

@media screen and (min-width:768px) {
  table {
    font-size: small !important;
  }
}

@media (max-width: 600px) {
  .codeColumn {
    display: none;
  }
}