.notificationText {
    text-align: start;
    margin-inline-start: 15px;
    color: #8A8A8A;
}

.notificationTextTitle {
    text-align: start;
    margin-inline-start: 15px;
    /* font-family: Roboto, Helvetica, Arial, sans-serif;
    font-weight: 400;
    font-size: 16px; */
    margin-top: 17px;
    /* line-height: 24px; */
    font-family: Poppins;
    font-size: 12px;
    font-weight: 600;
    color: #594e4e;
}

.notificationHead {
    /* color: rgb(255, 128, 43); */
    text-align: left;
    font-family: Poppins !important;
    font-size: 24px;
    font-weight: 600;
    color: #012248;
}
.Loader{
    color: #012248 !important;
    font-family: Poppins !important;
    text-align: center !important;
}

.card {
    /* background-color: #ff9800 !important; */
    /* color: #ffff !important; */
    /* font-family: Roboto, Helvetica, Arial, sans-serif;
    font-weight: 400;
    font-size: 14px;
    line-height: 24px;
    border: 0.5px solid #8A8A8A;
    box-shadow: none;
    margin: 10px 0px 10px 0px; */
    font-family: Poppins !important;
    font-weight: 400;
    font-size: 14px;
    line-height: 24px;
    border-radius: 2px !important;
    box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.05) !important;
    margin: 12px 0px 12px 0px;

}

.floatingHead {
    float: left;
    border-radius: 2px;
    /* height: 50px;
    width: 50px;
    margin: 5px; */
    /* background: #FFF1E8; */
    /* color: #FF802B; */
    width: 6rem;
    height: 6rem;
    text-align: center;
    color: #594e4e;
    font-family: Poppins;
    font-size: 12px;
    margin: 5px;
    background-color: #d3f3f7;
    padding: 4px;
}