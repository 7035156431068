.cardPageTitle{
    text-align: initial;
    color: #ffa726;
}
.ExploreOurTestSeries {
    /* width: 822px; */
    height: 35px;
    /* flex-grow: 0; */
    /* margin: 0 135px 42px 0; */
    font-family: Poppins;
    font-size: 2.5rem;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: left;
    color: #012248;
  }
  .ExploreourTestSeriesstyle1 {
    font-weight: 600;
  }
  .ExploreourTestSeriesstyle2 {
    font-weight: bold;
  }