.cardRow {
  /* margin-top: 10%;
  margin-left: 25%; */

  text-align: center;
  background-image: url('/public/register.jpeg');
  background-repeat: no-repeat;
  /* background-size: 100%; */
  /* full image css */
  color: #FFF;
  border: 0;
  height: 100%;
  margin: 0;
  display: flex !important;
  padding: 120px 0;
  position: relative;
  min-height: 100vh;
  align-items: center;
  background-size: cover;
  background-position: center center;
  overflow-x: hidden;
}

.customdatepickerpopup .ant-picker-dropdown {
  z-index: 9999 !important;
}

.logincard {
  padding: '11px 11px 0px';
  z-index: 9;
  box-shadow: 10px 16px 28px #000000;
  /* zIndex: '9', 
  boxShadow: '10px 16px 28px #000000' */
}

.signInFields {
  border-right: 10px solid rgba(100, 153, 0, 1);
  border-radius: 5px;
}

.signinCard {
  background-color: #ffffff;
}

.signInLogo {
  background-color: rgba(219, 246, 188, 0.3);
}

.signInLogo p {
  position: relative;
  top: 8%;
  left: 20%;
  height: 26%;
  width: 70%;
  font-family: "Montserrat";
  font-size: 100%;
  font-weight: 500;
  color: rgba(130, 130, 130, 1);
  /* letter-spacing: 0px; */
  line-height: 120%;
}

.signInLogo div img {
  position: relative;
  top: 30px;
  left: 27px;
  height: 45%;
  width: 50%;
}

.signInLogo img {
  position: relative;
  top: 5%;
  /* left: 47px; */
  height: 14%;
  width: 53%;
}

.loginButton {
  /* background-color: #EC9706 !important; */
  color: #ffffff;
  width: 100%;
  font-family: 'Poppins';
}

.cardRow button {
  /* padding: 8px 103px; */
  /* background-color: #FF802B; */
  block-size: 50%;
  /* margin-right: 50px; */
  margin-bottom: 22px;
}

.login-form-forgot {
  margin-left: 98px;
}

.sigupLink {
  color: #EC9706;
}

.Sheading {
  color: #000000;
  /* font: normal normal bold 24px/35px; */
  top: 80px;
  left: 903px;
  font-size: 20px;
  /* font-family: 'Poppins'; */
  font-weight: bolder;
  /* margin-top: -8%; */
  /* width: 193px; */
  height: 33px;
  letter-spacing: 0px;

  /* 
  text-align: left;
  align-items: c;
  margin: revert; */
  /* margin-right: 77%; */
}

.label {
  font-size: 13px;
  display: flex;
  color: #8C8C8C;
  font-family: Poppins !important;
  font-weight: 500;
  line-height: 24px;
}

.cardHeading {
  font-family: Poppins !important;
  color: #002147;
  font-weight: bold;
}

.toSignUp {
  font-size: 13px;
  color: #8C8C8C;
  font-family: Poppins;
  font-weight: 500;
  line-height: 24px;
}

.greetingHeading {
  top: 80px;
  left: 80px;
  width: 308px;
  height: 93px;
  /* UI Properties */
  text-align: left;
  font: normal normal bold 32px/48px 'poppins';
  letter-spacing: 0px;
  color: #000000;
  opacity: 1;
}

.Module {
  background-color: rgb(255, 255, 255);
  border-radius: 20px;
  padding: 11%;
}

.forgotPass {
  /* color: #EC9706; */
  float: left
    /* right; */
}

.right2image {
  margin-top: 25%;
  /* background-repeat: no-repeat;
  background-image: url('../../public/right2.JPG') */
}

.rememberMe {
  float: left;
  color: #BFBFBF;
  font-weight: 100;
}

.minControls {
  margin-top: -4%;
}

.Hline {
  display: flex;
  flex-direction: row;
}

.Hline:before,
.Hline:after {
  content: "";
  flex: 1 1;
  border-bottom: 1px solid;
  margin: auto;
  color: #4B4646;
  font-weight: 100;
}

.Hline:before {
  margin-right: 10px
}

.Hline:after {
  margin-left: 10px
}

.flotingButtons Button {
  padding: 12px 16px 12px 16px;
  border-radius: 40px;
  margin-left: 5%;
}

/* .signinbody{
  background-color: #83cadb;
} */

/* responsive */
@media only screen and (min-width: 600px) {
  .responsive {
    margin-inline-start: 27%;
  }
}

@media only screen and (max-width: 600px) {
  .responsive {
    margin: 11%;
  }
}