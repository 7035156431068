.Rectangle1 {
  /* width: 1440px; */
  height: 527px;
  /* margin: 0 0 124px; */
  /* padding: 28px 59px 111px 32px; */
  /* background-image: linear-gradient(to bottom, #b8dcee, #e9fde0); */
}

.UnlockYourPotential {
  width: 768px;
  height: 58px;
  /* margin: 75px 67px 24px 0; */
  font-family: Poppins;
  /* font-size: 52px; */
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #002147;
}

.subHeading {
  width: 789px;
  height: 44px;
  /* margin: 24px 46px 35px 0; */
  font-family: Poppins;
  font-size: 17px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  text-align: left;
  color: #002147;
}

.Rectangle4 {
  /* width: 1248px; */
  /* height: 155px; */
  /* flex-grow: 0; */
  margin-top: 275;
  /* margin: 460px 96px 36px; */
  /* padding: 19px 62px 40px 37px; */
  /* border-radius: 5px; */
  /* box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.05); */
  /* background-color: #fff; */
}

.ScoreRectangle {
  /* height: 155px;
  position: relative;
  border-radius: 5px;
  */
  padding: 19px 37px;
  box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.05);
  background-color: #fff;
  display: flex;
  justify-content: space-around;
}

.scoreCardNumbers {
  width: 96px;
  height: 35px;
  flex-grow: 0;
  margin: 0 11px 12px 13.8px;
  font-family: Poppins;
  font-size: 48px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #002147;
}

.scoreCardLabel {
  width: 119px;
  height: 21px;
  flex-grow: 0;
  margin: 12px 0 0 80px;
  font-family: Poppins;
  font-size: 24px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  /* line-height: normal; */
  letter-spacing: normal;
  text-align: center;
  color: #000;
}

/* .ScoreRectangle {
  height: 155px;
  padding: 19px 37px;
  border-radius: 5px;
  box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.05);
  background-color: #fff;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap; 
}

.scoreCardItem {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  margin: 0 10px;
}

.scoreCardNumbers {
  width: 96px;
  height: 35px;
  margin: 0 11px 12px 13.8px;
  font-family: Poppins;
  font-size: 48px;
  font-weight: bold;
  color: #002147;
}

.scoreCardLabel {
  margin-top: 12px;
  font-family: Poppins;
  font-size: 24px;
  font-weight: normal;
  color: #000;
} */



.achievementImage {
  position: absolute;
  top: 21%;
  /* Centers the image vertically */
  left: 60%;
  /* Centers the image horizontally */
  transform: translate(-50%, -50%) translateX(-20%);
  /* Centers and moves the image 20% out of the container */
  width: auto;
  /* Maintains the image aspect ratio */
  /* height: 60%; */
  /* Set height of the image */
  max-width: 60%;
  /* Ensures the image doesn’t stretch too wide */
}

.Rectangle5 {
  /* width: 1440px;
    height: 2013px; */
  /* margin: 36px 0 0; */
  /* margin-top: 20px; */
  /* position: relative; */
  /* padding: 70px 0px 200px; */
  background-color: #002147;
}

.Discovertheperfectonlineprogram {
  /* width: 617px; */
  /* height: 35px; */
  /* margin: 0 33px 75px 32px; */
  font-family: Poppins;
  /* font-size: 32px; */
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #fff;
}

.Rectangle22 {
  /* width: 1440px; */
  /* height: 64px; */
  flex-grow: 0;
  margin: 66px 0 64px;
  padding: 10px 10px 0 8vh;
  background-color: rgba(255, 255, 255, 0.05);
}

.Group {
  /* width: 1440px; */
  /* height: 100%; */
  background-repeat: no-repeat;
  /* height: 86vh; */
  flex-grow: 0;
  margin: -121px 0 0;
  padding: 10% 4%;
  text-align: left;
  /* opacity: 0.12; */
}

.Whiletheresnosecretformulaforgettingintogreatcollegeswecansupportyouinbuildingconf {
  width: 702px;
  height: 324px;
  margin: 16.6px 27px 8.4px 26.6px;
  font-family: Poppins;
  /* font-size: 32px; */
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #000;
  margin-inline-start: auto;
}

.Whiletheresnosecretformulaforgettingintogreatcollegeswecansupportyouinbuildingconf .textstyle1 {
  font-weight: bold;
  color: #fff;
}

.Rectangle19 {
  width: 625px;
  height: 275px;
  /* margin: 42px 46px 0 0; */
  /* padding: 84px 90px 37px 37px; */
  border-radius: 10px;
  box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.05);
  background-color: #fff;
}


/* Rectangle quotation marks style */
.Rectangle19span {
  /* width: 152px;
  height: 192px; */
  /* margin-left: 27px; */
  opacity: 0.05;
  font-family: JockeyOne;
  font-size: 256px;
  font-weight: normal;
  letter-spacing: normal;
  text-align: left;
  color: #313e5b;
  position: absolute;
  margin-top: 70px;
  /* transform: rotate(-30deg); */
  /* Ensure the quotation mark is rotated */
}

/* Feedback text container */
.stuentFeedback {
  /* Ensures feedback section scales with the card */
  width: 100%;
  min-height: 100px;
  /* Minimum height for the feedback area */
  padding: 10px;
  /* Add some padding to prevent text from touching the edges */

  font-family: Poppins;
  font-size: 16px;
  font-weight: normal;
  line-height: 1.5;
  /* Improve line spacing for readability */
  text-align: left;
  color: #092c54;
  margin-top: 35px;
  margin-bottom: 15px;

  word-wrap: break-word;
  /* Allows words to break and avoid overflow */
  overflow-wrap: break-word;
  /* Ensures long words break properly */
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  /* max-height: 200px; */
  /* Prevent content from overflowing vertically */
  overflow: hidden;
  /* Hide overflowing content */
}

/* Student name styling */
.stuentFeedback .textStudentName {
  text-align: end;
  line-height: 2;
  font-weight: bold;
  font-size: 14px;
  color: #092c54;
}

/* .Rectangle19span {
  width: 152px;
  height: 192px;
  margin-left: 27px;
  opacity: 0.05;
  font-family: JockeyOne;
  font-size: 256px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
  text-align: left;
  color: #313e5b;
  position: absolute;
  margin-top: 70px;
} */

/* .stuentFeedback {
  height: 154px;
  font-family: Poppins;
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #092c54;
  margin-top: 50px;
  margin-bottom: 15px;
}

.stuentFeedback .textStudentName {
  font-weight: bold;
} */

.Rectangle21 {
  /* width: 178px; */
  height: 41px;
  font-family: Poppins !important;
  margin: 0px 21px 6px 21px !important;
  padding: 8px 62px 9px 63px !important;
  border-radius: 42px !important;
  box-shadow: 0 4px 2px 0 rgba(0, 0, 0, 0.05);
  background-color: #fff !important;
  color: #000000 !important;
  text-transform: none !important;
  font-size: 16px !important;
}

.Rectangle3 {
  /* width: 234px !important;
  height: 56px !important; */
  flex-grow: 0;
  margin: 35px 408px 46px 0 !important;
  padding: 15px 48px 18px 43px !important;
  border-radius: 5px !important;
  background-color: #f8cb1e !important;
  color: #002147 !important;
  font-family: Poppins;
  /* font-size: 18px !important; */
  font-weight: bold !important;
  text-transform: none !important;
}

/* Test series cards style */
.Rectangle8 {
  /* width: 291px; */
  height: 420px;
  flex-grow: 0;
  /* margin: 65px 59px 128.1px 39px; */
  margin: 65px 25px 40px 25px;
  padding: 0 0 16px 0;
  border-radius: 5px;
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.05);
  /* border: solid 1px #ebebeb; */
  background-color: #fff;
}

.Rectangle24 {
  width: 119px;
  height: 31px;
  flex-grow: 0;
  margin: 18px 13px 0 15px;
  padding: 2px 7px 11px 14px;
  border-radius: 5px !important;
  border: solid 0.5px #002147 !important;
  background-color: #fff !important;
  font-family: Poppins !important;
  font-size: 16px !important;
  text-transform: none !important;
  color: #002147 !important;
}

.Rectangle25 {
  width: 119px;
  height: 31px;
  flex-grow: 0;
  margin: 18px 24px 0 13px;
  padding: 2px 7.9px 7.2px 22px;
  border-radius: 5px;
  background-color: #002147 !important;
  text-transform: none !important;
  font-family: Poppins !important;
  font-size: 16px !important;
  text-align: left !important;
  color: #fff !important;

}

.priceTag {
  color: #000000 !important;
  font-size: larger;
  transform: rotate(91deg);
}

.price {
  /* width: 53px; */
  height: 18px;
  flex-grow: 0;
  margin: 13px 0 3px 12px;
  font-family: Poppins;
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #594e4e;
}

.peoples {
  color: #000000 !important;
  font-size: larger;
}

.cardDescription {
  font-family: Poppins;
  color: #594e4e !important;
}

.Line9 {
  width: 254px;
  height: 1px;
  flex-grow: 0;
  margin: 15px 18px 9px 19px;
  background-color: #cacaca;
}

.TestSeriesTitle {
  /* width: 176px; */
  height: 24px;
  flex-grow: 0;
  margin: 5px 5px 5px 1px;
  font-family: Poppins;
  font-size: 16px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #000;
}

.filterButton {
  background: #fff !important;
  width: 44px !important;
  height: 44px !important;
  margin-right: 10px !important;

}

.filterIcon {
  font-size: x-large !important;
  color: #000 !important;
}

.filterLabel {
  /* width: 52px;
      height: 23px; */
  /* flex-grow: 0; */
  /* margin: 11px 15px 11px 10px; */
  padding: 7px 10px;
  font-family: Poppins !important;
  font-size: 16px !important;
  font-weight: normal !important;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #fff;
}

.dividerX {
  margin: 0px 23px 11px 15px !important;
  background-color: white !important;
}

.activeFilter {
  background-color: #d5caf4 !important;
}

.filterButtonFonts {
  /* width: 79px;
    flex-grow: 0; */
  margin: 0 12px 10px 0 !important;
  /* padding: 13px 28px 12px !important; */
  border-radius: 78px !important;
  height: 44px;
  font-family: Poppins !important;
  /* font-size: 16px !important; */
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #474747 !important;
}

.newLaunchBadge {
  width: 88px;
  /* height: 19px; */
  position: absolute;
  /* flex-grow: 0; */
  /* margin: 132px 59px 23px 0; */
  padding: 0px 10px 0px 11px !important;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1) !important;
  background-image: linear-gradient(to right, #e5cf06, #dcd577) !important;
  font-family: Poppins !important;
  font-size: 10px !important;
  color: #594e4e !important;
  top: 165 !important;
  left: 26 !important;
  z-index: 1 !important;

}

.MEDINEERfrMobile {
  color: #002147;
  font-size: 24px;
  font-weight: 900;
}

.MEDINEER {
  width: 167px;
  height: 39px;
  /* margin: 2px 668px 0 0; */
  /* font-family: Inter; */
  font-size: 32px;
  font-weight: 900;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #002147;
  cursor: pointer
}

.studentFeedbackHeadline {
  font-family: Poppins !important;
  /* font-size: 32px !important; */
  font-weight: bold !important;
  text-align: left !important;
  color: #092c54 !important;
}

.defaultBackground {
  background-color: #f2f2f2;
  padding-top: 80px;
}

/* Media Queries for Responsiveness */

@media (max-width: 1200px) {
  .ScoreRectangle {
    padding: 19px 25px;
  }

  .scoreCardNumbers {
    font-size: 42px;
    /* Slightly smaller font size on medium screens */
  }

  .scoreCardLabel {
    font-size: 22px;
  }
}

@media (max-width: 900px) {
  .ScoreRectangle {
    flex-direction: column;
    padding: 15px 20px;
    /* Adjust padding for smaller screens */
  }

  .scoreCardItem {
    margin: 10px 0;
    /* Stack items vertically */
  }

  .scoreCardNumbers {
    font-size: 36px;
    /* Smaller numbers on smaller screens */
  }

  .scoreCardLabel {
    font-size: 20px;
    /* Reduce font size */
  }
}

@media (max-width: 600px) {
  .ScoreRectangle {
    padding: 10px 15px;
    /* More compact padding for mobile */
  }

  .scoreCardNumbers {
    font-size: 30px;
    /* Further reduce font size on very small screens */
  }

  .scoreCardLabel {
    font-size: 18px;
    /* Smaller label font size */
  }
}

/* for Tablets incorrect */
@media screen and (min-width: 1024px) and (min-height: 538px) {
  .MobileGroup {
    display: none;
  }

  .Whiletheresnosecretformulaforgettingintogreatcollegeswecansupportyouinbuildingconf {
    font-size: 20px;
  }

  .Rectangle5 {
    padding: 70px 0px 100px;
  }


}

/* for large display */
@media (min-width: 1440px) {

  .bannerofHeader {
    background-image: url('../../public/header1.jpg') !important;
    height: 700px
  }

  /* .defaultBackground {
    padding-top: 5vh;
  } */

  .Group {
    height: 99vh !important;
  }

  .MobileGroup {
    display: none;
  }

  .feedbackSection {
    padding-inline: 35px !important;
  }

  .centerContent {
    justify-content: center !important;
  }
}

/* Medium to Large Screens (1200px - 1440px, medium desktops or large laptops) */

@media (min-width: 1200px) and (max-width: 1439px) {
  .centerContent {
    justify-content: center !important;
  }

  .bannerofHeader {
    background-image: url('../../public/header1.jpg') !important;
    height: 600px
  }

  /* .defaultBackground {
    padding-top: 5vh;
  } */

  .Group {
    height: 99vh !important;
  }

  .MobileGroup {
    display: none;
  }

  .feedbackSection {
    padding-inline: 35px !important;
  }
}

/* Tablets (768px - 1199px, tablets and medium-sized screens) */
@media (min-width: 768px) and (max-width: 1199px) {
  .filterButtonFonts {
    font-size: 12px !important;
  }

  .UnlockYourPotential {
    font-size: 52px;
  }

  .Discovertheperfectonlineprogram {
    font-size: 32px;
  }

  .studentFeedbackHeadline {
    font-size: 32px !important;
  }

  .Rectangle5 {
    padding: 70px 0px 200px;
    margin-top: 20px;
  }

  .Rectangle8 {
    margin: 65px 25px 40px 25px;
  }

  .DiscovertheperfectonlineprogramCover {
    text-align: left;
    margin-bottom: 40px !important;
  }

  .MCards {
    width: 90%;
  }

  .MobileCards {
    display: none !important;
  }

  .Whiletheresnosecretformulaforgettingintogreatcollegeswecansupportyouinbuildingconf {
    font-size: 32px;
  }

  .bannerofHeader {
    background-image: url('../../public/header1.jpg') !important;
    height: 460px
  }

  .Rectangle3 {
    width: 234px !important;
    height: 56px !important;
    font-size: 18px !important;
  }

  /* .defaultBackground {
    padding-top: 7vh;
  } */

  .Group {
    height: 86vh !important;

  }

  .feedbackSection {
    padding-inline: 35px !important;
  }

  /* .centerContent {
    justify-content: center !important;
  } */

  /* .MobileGroup {
    display: none;
  } */
}

/* for small laptop */
@media screen and (min-width: 768px) and (min-height: 148px) {
  /* .centerContent {
    justify-content: center !important;
  } */

  .filterButtonFonts {
    font-size: 12px !important;
  }

  .UnlockYourPotential {
    font-size: 52px;
  }

  .Discovertheperfectonlineprogram {
    font-size: 32px;
  }

  .studentFeedbackHeadline {
    font-size: 32px !important;
  }

  .Rectangle5 {
    padding: 70px 0px 200px;
    margin-top: 20px;
  }

  .Rectangle8 {
    margin: 65px 25px 40px 25px;
  }

  .DiscovertheperfectonlineprogramCover {
    text-align: left;
    margin-bottom: 40px !important;
  }

  .MCards {
    width: 90%;
  }

  .MobileCards {
    display: none !important;
  }

  .Whiletheresnosecretformulaforgettingintogreatcollegeswecansupportyouinbuildingconf {
    font-size: 32px;
  }

  .bannerofHeader {
    background-image: url('../../public/header1.jpg') !important;
    height: 460px
  }

  .Rectangle3 {
    width: 234px !important;
    height: 56px !important;
    font-size: 18px !important;
  }

  /* .defaultBackground {
    padding-top: 7vh;
  } */

  .Group {
    height: 86vh !important;

  }

  .feedbackSection {
    padding-inline: 35px !important;
  }

  /* .centerContent {
    justify-content: center !important;
  } */

  /* .MobileGroup {
    display: none;
  } */
}




/* for mobile */
/* @media screen and (max-width: 768px) and (max-height: 538px) { */
@media only screen and (max-width: 767px) {

  /* .defaultBackground {
    padding-top: 7vh;
  } */

  .bannerofHeader {
    background: linear-gradient(to bottom, #b8dcee, #e9fde0) !important;
    height: 460px;
  }

  .Whiletheresnosecretformulaforgettingintogreatcollegeswecansupportyouinbuildingconf {
    font-size: 15px;
  }

  .Rectangle5 {
    margin-top: 100px;
  }

  .MobileCards {
    position: absolute;
    /* top: '100%',
      left: '50%', */
    transform: translate(-50%, -50%);
    max-width: 100%;
    width: 100%;
    display: grid;
    grid-template-columns: 1fr 1fr;
    background-color: #fff;
    padding: 20px;
    border-radius: 5px,
  }

  .studentFeedbackHeadline,
  .Discovertheperfectonlineprogram,
  .UnlockYourPotential {
    font-size: 24px !important;
  }

  .DiscovertheperfectonlineprogramCover {
    text-align: left;
    margin-bottom: 0;
  }

  .Rectangle8 {
    margin: 6px 25px 40px 25px;
    /* 65px 65px 40px 0px; */
  }

  .Rectangle5 {
    padding: 70px 0px 75px;

  }

  .scoreIcons1 {
    height: 24px;
    width: 57px;
  }

  .scoreIcons2 {
    height: 24px;
    width: 22px;
  }

  .scoreIcons3 {
    height: 24px;
    width: 29.6px;
  }

  .scoreIcons4 {
    height: 22.6px;
    width: 24px;
  }

  .MCards,
  .Rectangle22,
  /* .Rectangle3, */
  .Group {
    display: none;
  }

  .scoreCardLabel {
    /* float: right; */
    margin: 0;
    font-size: 15px;
  }

  .scoreCardNumbers {
    font-size: 17px;
  }

  .filterButtonFonts {
    font-size: 10px !important;
  }

  .Rectangle3 {
    height: 38px !important;
    width: 174px !important;
    font-size: 12px !important;
  }

  /* .Rectangle3 {
    visibility: hidden;
  } */

  .dividerX,
  .filterLabel,
  .filterButton,
  .filterIcon {
    display: none;
  }

  .productInfo {
    margin-left: 9% !important;
  }
}

/* for tablets */
@media only screen and (min-width: 768px) and (max-width: 1023px) {

  .bannerofHeader {
    background: linear-gradient(to bottom, #b8dcee, #e9fde0) !important;
    height: 460px
  }

  .Whiletheresnosecretformulaforgettingintogreatcollegeswecansupportyouinbuildingconf {
    font-size: 15px;
  }

  .Rectangle5 {
    margin-top: 100px;
  }

  .MobileCards {
    position: absolute;
    /* top: '100%',
        left: '50%', */
    transform: translate(-50%, -50%);
    max-width: 100%;
    width: 100%;
    display: grid;
    grid-template-columns: 1fr 1fr;
    background-color: #fff;
    padding: 20px;
    border-radius: 5px,
  }

  .studentFeedbackHeadline,
  .Discovertheperfectonlineprogram,
  .UnlockYourPotential {
    font-size: 24px !important;
  }

  .DiscovertheperfectonlineprogramCover {
    text-align: left;
    margin-bottom: 0;
  }

  .Rectangle8 {
    margin: 65px 25px 40px 25px;
    /* 65px 65px 40px 0px; */

  }

  .Rectangle5 {
    padding: 70px 0px 75px;

  }

  .scoreIcons1 {
    height: 24px;
    width: 57px;
  }

  .scoreIcons2 {
    height: 24px;
    width: 22px;
  }

  .scoreIcons3 {
    height: 24px;
    width: 29.6px;
  }

  .scoreIcons4 {
    height: 22.6px;
    width: 24px;
  }

  .MCards,
  .Rectangle22,
  /* .Rectangle3, */
  .Group {
    display: none;
  }

  .scoreCardLabel {
    /* float: right; */
    margin: 0;
    font-size: 15px;
  }

  .scoreCardNumbers {
    font-size: 17px;
  }

  .filterButtonFonts {
    font-size: 10px !important;
  }

  .Rectangle3 {
    height: 38px !important;
    width: 174px !important;
    font-size: 12px !important;
  }

  /* .Rectangle3 {
      visibility: hidden;
    } */

  .dividerX,
  .filterLabel,
  .filterButton,
  .filterIcon {
    display: none;
  }

  .productInfo {
    margin-left: 9% !important;
  }
}

/* Small Tablets to Large Phones (600px - 767px) */
@media (min-width: 600px) and (max-width: 767px) {

  .bannerofHeader {
    background: linear-gradient(to bottom, #b8dcee, #e9fde0) !important;
    height: 460px
  }

  .Whiletheresnosecretformulaforgettingintogreatcollegeswecansupportyouinbuildingconf {
    font-size: 15px;
  }

  .Rectangle5 {
    margin-top: 100px;
  }

  .MobileCards {
    position: absolute;
    /* top: '100%',
        left: '50%', */
    transform: translate(-50%, -50%);
    max-width: 100%;
    width: 100%;
    display: grid;
    grid-template-columns: 1fr 1fr;
    background-color: #fff;
    padding: 20px;
    border-radius: 5px,
  }

  .studentFeedbackHeadline,
  .Discovertheperfectonlineprogram,
  .UnlockYourPotential {
    font-size: 24px !important;
  }

  .DiscovertheperfectonlineprogramCover {
    text-align: left;
    margin-bottom: 0;
  }

  .Rectangle8 {
    /* margin: 65px 65px 40px 0px; */
    margin: 65px 25px 40px 25px;

  }

  .Rectangle5 {
    padding: 70px 0px 75px;

  }

  .scoreIcons1 {
    height: 24px;
    width: 57px;
  }

  .scoreIcons2 {
    height: 24px;
    width: 22px;
  }

  .scoreIcons3 {
    height: 24px;
    width: 29.6px;
  }

  .scoreIcons4 {
    height: 22.6px;
    width: 24px;
  }

  .MCards,
  .Rectangle22,
  /* .Rectangle3, */
  .Group {
    display: none;
  }

  .scoreCardLabel {
    /* float: right; */
    margin: 0;
    font-size: 15px;
  }

  .scoreCardNumbers {
    font-size: 17px;
  }

  .filterButtonFonts {
    font-size: 10px !important;
  }

  .Rectangle3 {
    height: 38px !important;
    width: 174px !important;
    font-size: 12px !important;
  }

  /* .Rectangle3 {
      visibility: hidden;
    } */

  .dividerX,
  .filterLabel,
  .filterButton,
  .filterIcon {
    display: none;
  }

  .productInfo {
    margin-left: 9% !important;
  }
}

@media (max-width: 599px) and (orientation: landscape) {

  .bannerofHeader {
    background: linear-gradient(to bottom, #b8dcee, #e9fde0) !important;
    height: 460px
  }

  .Whiletheresnosecretformulaforgettingintogreatcollegeswecansupportyouinbuildingconf {
    font-size: 15px;
  }

  .Rectangle5 {
    margin-top: 100px;
  }

  .MobileCards {
    position: absolute;
    /* top: '100%',
        left: '50%', */
    transform: translate(-50%, -50%);
    max-width: 100%;
    width: 100%;
    display: grid;
    grid-template-columns: 1fr 1fr;
    background-color: #fff;
    padding: 20px;
    border-radius: 5px,
  }

  .studentFeedbackHeadline,
  .Discovertheperfectonlineprogram,
  .UnlockYourPotential {
    font-size: 24px !important;
  }

  .DiscovertheperfectonlineprogramCover {
    text-align: left;
    margin-bottom: 0;
  }

  .Rectangle8 {
    /* margin: 65px 65px 40px 0px; */
    margin: 65px 25px 40px 25px;

  }

  .Rectangle5 {
    padding: 70px 0px 75px;

  }

  .scoreIcons1 {
    height: 24px;
    width: 57px;
  }

  .scoreIcons2 {
    height: 24px;
    width: 22px;
  }

  .scoreIcons3 {
    height: 24px;
    width: 29.6px;
  }

  .scoreIcons4 {
    height: 22.6px;
    width: 24px;
  }

  .MCards,
  .Rectangle22,
  /* .Rectangle3, */
  .Group {
    display: none;
  }

  .scoreCardLabel {
    /* float: right; */
    margin: 0;
    font-size: 15px;
  }

  .scoreCardNumbers {
    font-size: 17px;
  }

  .filterButtonFonts {
    font-size: 10px !important;
  }

  .Rectangle3 {
    height: 38px !important;
    width: 174px !important;
    font-size: 12px !important;
  }

  /* .Rectangle3 {
      visibility: hidden;
    } */

  .dividerX,
  .filterLabel,
  .filterButton,
  .filterIcon {
    display: none;
  }

  .productInfo {
    margin-left: 9% !important;
  }
}