.drawerList {
    font-size: 14px;
    line-height: 24px;
    font-weight: 400;
    color: #ffffff;
    display: grid !important;
}

.listItemButton {
    margin: 7px 0 7px 7px !important;
    justify-content: flex-start !important;
}

/* .listItemButton :active {
    background-color: #EC9706;
}

.listItemButton :hover {
    background-color: #EC9706;
} */

.drawerListIcon {
    font-size: 24px !important;
    margin-right: 31px;
    color: #ffffff;
}

.profileToolbar {
    /* background-color: #EC9706; */
    font-weight: bold;
    color: #ffffff;
}

.profileToolbar img {
    border-radius: 50%;
    height: 40px;
    width: 40px;
    margin-right: 15px;
}

.iconButton {
    margin-left: auto !important;
}

.profile {
    border-radius: 50%;
    height: 30px;
    width: 30px;
    margin: 0px 0px 24px 0px;
    /* margin-right: 15px;
    margin-bottom: 26px; */
    position: absolute;
}

.logoutBtn {
    border: none;
    /* position: absolute; */
    color: #ffffff;
    background-color: transparent;
    border-radius: 50%;
    /* margin-bottom: 24px; */
}

.searchBar {
    box-shadow: none !important;
    border-radius: 5px;
    border: 0.30000001192092896px solid #707070;
    margin-inline-end: 111px;
}

.searchIcon {
    height: 20px;
    width: 20px;
}

.providerName {
    font-family: Poppins !important;
    font-size: 22px !important;
    color: #002147 !important;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
}

@media screen and (max-width:768px) {
    .mobileButton {
        display: none !important;
    }
}

@media screen and (min-width:768px) {
    .webButton {
        display: none !important;
    }
}

.sidebarDashboard {
    font-family: 'Poppins';
}

@media screen and (max-width:768px) {
    .sidebarDashboard {
        font-family: 'Poppins';
        /* display: none; */
        position: fixed;
        z-index: 9;
        background: #707070;
        height: 100%;

    }
}

/* .bar {
    display: flex;
    position: fixed;
    top: 2rem;
    z-index: 9;
    background: #EC9706;
} */

/* sx={{
    // display: "flex",
    // alignItems: "center",
    // justifyContent: "flex-end",
    // px: [1],
  }} */