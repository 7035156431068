.timerControl {
    float: left;
    margin-left: 1.4%;
    color: #453535;
    font-weight: normal;
    /* font-family: 'Roboto, Helvetica, Arial, sans-serif'; */
    font-family: Poppins;
    font-size: 1.6rem;
    margin-top: 5px;
}

.timerIcon {
    font-size: 30px;
    margin-right: 10px
}